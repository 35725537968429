/*-------------------------------------------------------------------------------------------------------------------------------*/
/* This is main CSS file that contains custom style rules used in this template */
/*-------------------------------------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------*/
/* TABLE OF CONTENTS: */
/*--------------------------------------------------------*/
/* 00 - VARIABLES */
/* 01 - CSS RESET BY ERIC MEYER */
/* 02 - HEADER */
/* 03 - FOOTER */
/*-------------------------------------------------------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700|Montserrat:100,200,400,500,600,700,800,900|Oswald:200,300,400,500,600,700");
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 00 - VARIABLES */
/*-------------------------------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 01 - CSS RESET BY ERIC MEYER */
/*-------------------------------------------------------------------------------------------------------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
  border-left: 3px solid #333;
  padding-left: 20px;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #333;
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
}

table thead th,
table thead td,
table tbody th,
table tbody td {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  border: 1px solid #333;
  text-align: center;
  padding: 8px;
}

ul,
ol {
  margin-bottom: 20px;
}

ul li ul,
ul li ol,
ol li ul,
ol li ol {
  padding-left: 20px;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}

dl {
  margin-bottom: 20px;
}

dl dd {
  padding-left: 20px;
}

li,
dl,
dd {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  line-height: 24px;
}

h1, h2, h3, h4, h5, h6, p {
  margin-bottom: 15px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  color: #131313;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p,
.wp-caption-text,
.pingback,
address {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", serif;
  color: #888;
  letter-spacing: 1.12px;
  margin-bottom: 30px;
}

.pingback {
  margin-bottom: 15px;
}

blockquote {
  margin: 25px 0 30px;
  overflow: hidden;
  text-align: center;
  padding: 18px 42px 20px 28px;
  border-left: 4px solid #c0af94;
  background-color: #ebebeb;
}

blockquote p {
  font-size: 16px;
  line-height: 31px;
  font-family: "Libre Baskerville", serif;
  color: #131313;
  text-align: left;
  font-style: normal;
  margin: 0;
  letter-spacing: 1.28px;
}

pre {
  font-family: "Libre Baskerville", serif;
  font-size: 16px;
  color: #131313;
  line-height: 31px;
  padding: 20px;
  margin-bottom: 20px;
}

strong {
  font-weight: 700;
}

acronym {
  border-bottom: 1px dashed #777;
}

big {
  font-size: 2em;
}

i, cite, em, var, address, dfn {
  font-style: italic;
}

s, strike, del {
  text-decoration: line-through;
}

a,
a:visited {
  color: #c0af94;
}

a:hover {
  color: #131313;
  text-decoration: none;
}

a:focus,
a:active {
  text-decoration: none;
}

.rel-position {
  position: relative;
}

.no-padd {
  padding-left: 0;
  padding-right: 0;
}

.marg-10t {
  margin-top: 10px;
}

.marg-35t {
  margin-top: 35px;
}

.marg-40t {
  margin-top: 40px;
}

.marg-65t {
  margin-top: 65px;
}

body {
  overflow-x: hidden;
}

.site {
  position: relative;
  left: 0;
  transition: left .5s ease-out;
}

.content-site {
  padding: 80px 15px;
  position: relative;
  /** Full size content **/
}

.content-site.container {
  transition: width .3s ease-out;
}

.content-site .container {
  transition: width .3s ease-out;
}

.content-site .container .col-md-8 {
  transition: width .3s ease-out;
}

.page-template-about .content-site {
  padding: 15px;
}

.vertical-align {
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#loading {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
  margin-top: 0;
  top: 0;
  transition: background-color .3s ease-out;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 02 - HEADER */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.no-menu {
  margin-bottom: 15px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  color: #fff;
  line-height: 24px;
}

.no-menu a:hover {
  color: #888;
}

.site-header {
  width: 335px;
  float: left;
  text-align: right;
  position: fixed;
  left: -335px;
  z-index: 100;
  background-color: #131313;
  transition: left .5s ease-out;
  height: 100%;
  padding-right: 20px;
  /** Logo site **/
  /** Navigation menu **/
  /** Social icons **/
}

.site-header.active {
  left: 0;
}

.site-header .content-header {
  position: absolute;
  top: 50%;
  left: 110px;
  max-width: 200px;
  transform: translateY(-50%);
}

.site-header .site-title a {
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.site-header .site-description {
  font: normal 12px "Libre Baskerville", serif;
  color: #8f8f8f;
}

.site-header .main-menu {
  margin-bottom: 28px;
  margin-right: -20px;
}

.site-header .main-menu .menu-item {
  list-style: none;
  position: relative;
  padding-right: 20px;
  line-height: 28px;
}

.site-header .main-menu .menu-item a {
  font: normal 12px "Montserrat", sans-serif;
  color: #fff;
  text-transform: uppercase;
  line-height: 28px;
  transition: color .3s ease-out;
}

.site-header .main-menu .menu-item a:hover {
  color: #c0af94;
  text-decoration: none;
}

.site-header .main-menu .menu-item.current-menu-item a {
  color: #c0af94;
}

.site-header .main-menu .menu-item .sub-menu {
  opacity: 0;
  visibility: hidden;
  background-color: #131313;
  border-left: 1px solid #c0af94;
  padding: 7px 20px 14px;
  text-align: left;
  position: absolute;
  top: 5px;
  left: 102%;
  min-width: 200px;
  margin: 0;
  z-index: 1000;
}

.site-header .main-menu .menu-item .sub-menu:before {
  content: '';
  border: 6px solid transparent;
  border-right: 6px solid #c0af94;
  position: absolute;
  top: 15px;
  left: -12px;
}

.site-header .main-menu .menu-item .sub-menu a {
  color: #fff;
  text-transform: capitalize;
  transition: color .3s ease-out;
}

.site-header .main-menu .menu-item .sub-menu a:hover {
  color: #c0af94;
}

.site-header .social-icons {
  padding-top: 3px;
}

.site-header .social-icons li {
  list-style: none;
  display: inline-block;
  margin-right: 15px;
}

.site-header .social-icons li:last-child {
  margin-right: 0;
}

.site-header .social-icons li .fa {
  color: #8c8c8c;
  font-size: 12px;
}

.site-header .social-icons li .fa:hover {
  color: #fff;
}

.site-header .copyright-footer {
  font: normal 12px "Libre Baskerville", serif;
  color: #8f8f8f;
}

/** Menu button **/
.c-button {
  height: 100%;
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  overflow: visible;
  background-color: transparent;
  text-transform: none;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  text-align: center;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  z-index: 100;
}

.c-button:hover,
.c-button:focus {
  text-decoration: none;
  outline: none;
}

.c-button:not(:disabled) {
  cursor: pointer;
}

.c-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.c-button--menu {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 28px;
  height: 14px;
  font-size: 0;
  line-height: 0;
  transition: left .5s ease-out;
  z-index: 50;
}

.c-button--menu:before,
.c-button--menu:after,
.c-button--menu .c-button__icon,
.c-button--menu .c-button__icon:before {
  position: absolute;
  width: 28px;
  height: 2px;
  background: #e4e4e4;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}

.c-button--menu:before,
.c-button--menu:after {
  content: "";
  left: 0;
  right: 0;
}

.c-button--menu:before {
  top: 10px;
}

.c-button--menu:after {
  bottom: 14px;
}

.c-button--menu .c-button__icon {
  top: 50%;
  left: 50%;
  margin-left: -14px;
  margin-top: -3px;
}

.c-button--menu .c-button__icon:before {
  content: "";
  top: 0;
  left: 0;
}

.c-button--menu.is-active:before {
  left: 100%;
  width: 0;
}

.c-button--menu.is-active:after {
  right: 100%;
  width: 0;
}

.c-button--menu.is-active .c-button__icon {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.c-button--menu.is-active .c-button__icon:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 03 - PAGE ABOUT */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.post-image,
.page-image,
.recent-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.post-image {
  height: 600px;
  margin-top: 4px;
}

.page-image {
  height: 500px;
}

.title-post,
.comment-reply-title,
.comment-title {
  font-family: "Oswald", sans-serif;
  line-height: 40px;
  color: #131313;
  letter-spacing: 3.1px;
  margin: 0;
  margin-bottom: 20px;
  text-transform: uppercase;
  word-wrap: break-word;
}

.comment-reply-title {
  font-size: 28px;
  letter-spacing: 2.8px;
  margin-bottom: 24px;
}

.subtitle-post,
.comment-date,
.comment-reply-link,
#cancel-comment-reply-link {
  font-family: "Montserrat", sans-serif;
  color: #c0af94;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  margin-bottom: 29px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  transition: color .3s ease-out;
}

#cancel-comment-reply-link:hover {
  color: #131313;
}

.link-post,
.load-btn,
button.comment-btn {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #131313;
  background-color: transparent;
  border: none;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  box-sizing: border-box;
  letter-spacing: 1.1px;
  line-height: 20px;
  padding: 8px 28px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  transition: all .3s ease-out;
}

.link-post:visited,
.load-btn:visited,
button.comment-btn:visited {
  color: #131313;
}

.link-post:focus,
.load-btn:focus,
button.comment-btn:focus {
  text-decoration: none;
}

.link-post:hover,
.load-btn:hover,
button.comment-btn:hover {
  background-color: #131313;
  color: #fff;
  border-color: #131313;
}

.link-post {
  margin-top: 10px;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 04 - PAGE ALBUMS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.albums,
.post {
  position: relative;
}

.albums.col-md-4 .albums-image,
.post.col-md-4 .albums-image {
  height: 250px;
}

.albums.col-md-6 .albums-image,
.post.col-md-6 .albums-image {
  height: 305px;
}

.albums.col-md-12 .albums-image,
.post.col-md-12 .albums-image {
  height: 470px;
}

.albums .img-gallery,
.post .img-gallery {
  width: 33.3333%;
  height: 33.3333%;
  float: left;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.albums .img-gallery a,
.post .img-gallery a {
  display: block;
  width: 100%;
  height: 100%;
}

.albums .albums-image,
.post .albums-image {
  position: relative;
}

.albums .albums-image a,
.post .albums-image a {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 100%;
}

.albums .albums-image .overlay,
.post .albums-image .overlay {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-out;
}

.albums .albums-image:hover .overlay,
.post .albums-image:hover .overlay {
  opacity: 1;
  visibility: visible;
}

.albums .content-post,
.post .content-post {
  text-align: right;
}

.albums .title-albums,
.post .title-albums {
  display: block;
  margin-top: 14px;
  margin-bottom: 0;
  text-align: right;
}

.albums .title-albums h3,
.albums .title-albums h5,
.post .title-albums h3,
.post .title-albums h5 {
  text-transform: uppercase;
  letter-spacing: 3.1px;
  transition: color .2s ease-out;
}

.albums .title-albums h3:hover,
.albums .title-albums h5:hover,
.post .title-albums h3:hover,
.post .title-albums h5:hover {
  color: #c0af94 !important;
}

.albums.col-md-12,
.post.col-md-12 {
  margin-bottom: 80px;
}

.albums.col-md-12 .title-albums,
.post.col-md-12 .title-albums {
  margin-top: 40px;
  text-align: center;
}

.albums.col-md-12 .title-albums h3,
.albums.col-md-12 .title-albums h5,
.post.col-md-12 .title-albums h3,
.post.col-md-12 .title-albums h5 {
  font-size: 36px;
}

.albums {
  margin-bottom: 25px;
}

.albums .title-albums {
  margin-top: 20px;
}

.albums .title-albums h5 {
  letter-spacing: 1.8px;
}

.post {
  margin-bottom: 34px;
}

.post.sticky {
  position: relative;
}

.post.sticky:before {
  position: absolute;
  top: 15px;
  left: 30px;
  font-family: FontAwesome;
  content: '\f08d';
  font-size: 20px;
  transform: rotate(325deg);
  z-index: 5;
}

.post.col-md-12 {
  margin-bottom: 48px;
}

.post.col-md-12 .title-albums {
  margin-top: 35px;
}

.post.col-md-12 .content-post {
  text-align: center;
}

/** Pagination **/
.pagination,
.navigation {
  display: inline-block;
  width: 100%;
}

.pagination .next-post,
.pagination .previus-post,
.navigation .next-post,
.navigation .previus-post {
  position: relative;
}

.pagination .next-post a,
.pagination .next-post span,
.pagination .previus-post a,
.pagination .previus-post span,
.navigation .next-post a,
.navigation .next-post span,
.navigation .previus-post a,
.navigation .previus-post span {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #585858;
  line-height: 1.7;
  letter-spacing: .7px;
  text-decoration: none;
  position: relative;
  transition: color .3s ease-out;
}

.pagination .next-post a:hover,
.pagination .next-post span:hover,
.pagination .previus-post a:hover,
.pagination .previus-post span:hover,
.navigation .next-post a:hover,
.navigation .next-post span:hover,
.navigation .previus-post a:hover,
.navigation .previus-post span:hover {
  color: #c0af94;
}

.pagination .next-post a:focus,
.pagination .next-post span:focus,
.pagination .previus-post a:focus,
.pagination .previus-post span:focus,
.navigation .next-post a:focus,
.navigation .next-post span:focus,
.navigation .previus-post a:focus,
.navigation .previus-post span:focus {
  text-decoration: none;
}

.pagination .next-post i,
.pagination .previus-post i,
.navigation .next-post i,
.navigation .previus-post i {
  font-size: 18px;
  position: absolute;
  top: 49%;
  -webkit-transform: translateY(-49%);
  transform: translateY(-49%);
  color: #c0af94;
}

.pagination .previus-post,
.navigation .previus-post {
  float: left;
  padding-left: 20px;
}

.pagination .previus-post i,
.navigation .previus-post i {
  left: 0;
  transition: left .3s ease;
}

.pagination .previus-post:hover i,
.navigation .previus-post:hover i {
  left: 5px;
  opacity: .7;
}

.pagination .next-post,
.navigation .next-post {
  float: right;
  padding-right: 20px;
}

.pagination .next-post i,
.navigation .next-post i {
  right: 0;
  transition: right .3s ease;
}

.pagination .next-post:hover i,
.navigation .next-post:hover i {
  right: 5px;
  opacity: .7;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 05 - SINGLE ALBUMS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.full-size-button {
  position: fixed;
  top: 30px;
  right: 30px;
  cursor: pointer;
  visibility: visible;
}

.full-size-button .fa {
  color: #d3d3d3;
  font-size: 20px;
}

.single-albums-image {
  margin-left: 15px;
  margin-right: 15px;
}

.single-albums-image .img-list-gallery {
  margin-bottom: 80px;
  cursor: pointer;
  position: relative;
}

.single-albums-image .img-list-gallery .full-size-button {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 15px;
  right: 30px;
  transition: opacity .3s ease-out;
}

.single-albums-image .img-list-gallery:hover .full-size-button {
  opacity: 1;
  visibility: visible;
}

.single-albums-image .img-list-gallery.col-md-6, .single-albums-image .img-list-gallery.col-md-4 {
  margin-bottom: 30px;
}

.single-albums-image .img-list-gallery.col-md-6 .img-wrapp, .single-albums-image .img-list-gallery.col-md-4 .img-wrapp {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.single-albums-image .img-list-gallery.col-md-6 .img-wrapp {
  height: 250px;
  transition: height .3s ease-out;
}

.single-albums-image .img-list-gallery.col-md-4 .img-wrapp {
  height: 200px;
  transition: height .3s ease-out;
}

.single-albums-image .img-list-gallery img {
  width: 100%;
}

/** Popup albums type **/
#content-site.full-size-list > .full-size-button {
  opacity: 1;
  visibility: visible;
}

#content-site.full-popup,
#content-site.full-popup .container,
#content-site.full-popup .col-md-8,
#content-site.full-popup .col-md-6,
#content-site.full-popup .col-md-4 {
  width: 100%;
}

#content-site.full-popup {
  padding: 0;
}

#content-site.full-popup .pagination,
#content-site.full-popup .navigation {
  padding: 0 30px;
}

#content-site.full-popup .c-button--menu,
#content-site.full-popup .full-size-button {
  opacity: 0;
  visibility: hidden;
}

#content-site.full-popup .container {
  padding: 30px;
}

#content-site.full-popup .img-list-gallery {
  margin-bottom: 30px;
}

#content-site.full-popup .img-wrapp {
  background-position: -9999px;
  height: auto;
}

#content-site.full-size.full-popup .img-wrapp {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#content-site.full-size-list.full-popup .img-wrapp img {
  display: block !important;
}

#content-site.full-popup .col-md-8 {
  margin: 0;
  padding: 0;
}

/** Full size popup **/
.full-img-popup {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 30px;
  width: 100%;
  height: 100%;
  z-index: 100;
}

/** Default popup **/
.mfp-title {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5px;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 06 - SINGLE BLOG */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.blog-default .post .overlay {
  display: none;
}

.blog-default .post .albums-image {
  height: inherit !important;
}

.blog-default .post .albums-image img {
  display: block !important;
}

.blog-default .post .albums-image a {
  background: none !important;
}

.list-info li {
  font-family: "Montserrat", sans-serif;
  color: #c0af94;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  list-style: none;
}

.list-info li:after {
  content: ' | ';
  color: #131313;
}

.list-info li:last-child:after {
  display: none;
}

.list-info li a {
  display: inline-block;
  color: #c0af94;
  transition: color .3s ease-out;
}

.list-info li a:hover {
  color: #131313;
}

.recent-posts .title-post {
  margin-bottom: 35px;
}

.recent-posts .recent-image {
  height: 240px;
}

.recent-posts .post .overlay {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .3s ease-out;
}

.recent-posts .post:hover .overlay,
.recent-posts .post:hover .recent-content {
  opacity: 0;
  visibility: hidden;
}

.recent-posts .post:hover .no-thumbnail .recent-content {
  opacity: 1;
  visibility: visible;
}

.recent-posts .recent-content {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  transition: all .3s ease-out;
}

.recent-posts .recent-content .recent-title {
  font-family: "Oswald", sans-serif;
  font-size: 18px !important;
  text-transform: uppercase;
  color: #fff !important;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.recent-posts .recent-content .recent-date {
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1.6px;
}

.recent-posts .no-thumbnail {
  display: block;
  height: 240px;
}

.recent-posts .no-thumbnail .recent-content .recent-title,
.recent-posts .no-thumbnail .recent-content .recent-date {
  color: #131313 !important;
}

.comment-respond {
  margin-top: 15px;
  margin-bottom: 30px;
}

.comment-respond .comment-form input,
.comment-respond .comment-form textarea {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  color: #888;
  border: 0;
  border-bottom: 2px solid #f7f7f7;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  letter-spacing: 1px;
  outline: none;
  padding: 3px 0 15px 0;
  width: 100%;
  transition: all .3s ease-out;
}

.comment-respond .comment-form input::-webkit-input-placeholder, .comment-respond .comment-form input::-moz-placeholder, .comment-respond .comment-form input:-moz-placeholder, .comment-respond .comment-form input:-ms-input-placeholder,
.comment-respond .comment-form textarea::-webkit-input-placeholder,
.comment-respond .comment-form textarea::-moz-placeholder,
.comment-respond .comment-form textarea:-moz-placeholder,
.comment-respond .comment-form textarea:-ms-input-placeholder {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  color: #888;
}

.comment-respond .comment-form input:focus,
.comment-respond .comment-form textarea:focus {
  border-bottom-color: #c0af94;
}

.comment-respond .comment-form textarea {
  padding-bottom: 18px;
  margin-top: 35px;
  resize: none;
}

.comment-respond .comment-form .comment-btn {
  margin-top: 38px;
  padding: 8px 53px;
}

.light-comments-list {
  margin-top: 30px;
}

.light-comments-list ol,
.light-comments-list ul {
  list-style: none;
}

.light-comments-list .comment-title {
  font-size: 19px;
  display: inline-block;
  margin-right: 20px;
  letter-spacing: 1.3px;
  margin-bottom: 13px;
  line-height: inherit;
}

.light-comments-list .comment-img {
  margin-right: 20px;
  float: left;
}

.light-comments-list .comment-body {
  padding-left: 12%;
  position: relative;
}

.light-comments-list .comment-body h2 {
  margin-top: 20px;
}

.light-comments-list .comment-reply-link {
  position: absolute;
  top: 0;
  right: 0;
}

.light-comments-list .comment-reply-link:hover {
  color: #131313;
}

.light-comments-list .children {
  padding-left: 70px;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 07 - WIDGETS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
#secondary {
  margin-top: 4px;
}

.sidebar .widget {
  margin-bottom: 75px;
}

.sidebar .widget .widget-title {
  font: 700 12px "Montserrat", sans-serif;
  color: #131313;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin-bottom: 26px;
}

.sidebar .widget ul {
  list-style: none;
  padding-left: 0;
}

.sidebar .widget li {
  margin-bottom: 12px;
}

.sidebar .widget strong {
  color: #131313;
}

.sidebar .widget a {
  font-family: "Libre Baskerville", serif;
  color: #888;
  transition: color .3s ease-out;
}

.sidebar .widget a:hover {
  color: #131313;
}

.sidebar .widget select {
  font-family: "Libre Baskerville", serif;
  color: #888;
  padding: 5px;
  width: 100%;
}

.sidebar .widget select option {
  font-family: "Libre Baskerville", serif;
  color: #888;
}

.sidebar .widget .children,
.sidebar .widget .sub-menu {
  padding-left: 0;
  margin-top: 12px;
}

.sidebar .widget .img {
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 190px;
}

.sidebar .widget.widget_latest_posts .img {
  position: relative;
}

.sidebar .widget.widget_latest_posts .img .overlay {
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-out;
}

.sidebar .widget.widget_latest_posts .img:hover .overlay {
  opacity: 1;
  visibility: visible;
}

.sidebar .widget.widget_latest_posts .link {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #888;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 15px;
  margin-bottom: 27px;
}

.sidebar .widget.widget_latest_posts .link:hover {
  color: #c0af94;
}

.sidebar .widget.widget_categories .cat-item {
  color: #c0af94;
}

.sidebar .widget.widget_categories .cat-item a {
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 1.3px;
}

.sidebar .widget.widget_archive li, .sidebar .widget.widget_pages li, .sidebar .widget.widget_nav_menu li {
  color: #c0af94;
}

.sidebar .widget.widget_tag_cloud .tagcloud a {
  display: inline-block;
  background-color: #f2f2f2;
  font-size: 12px !important;
  padding: 7px 10px 8px;
  letter-spacing: 1px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all .3s ease-out;
}

.sidebar .widget.widget_tag_cloud .tagcloud a:hover {
  background-color: #c0af94;
}

.sidebar .widget.widget_recent_comments .recentcomments {
  font-family: "Libre Baskerville", serif;
  line-height: 20px;
}

.sidebar .widget.widget_search .search-field {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  color: #888;
  border: 0;
  border-bottom: 2px solid #f7f7f7;
  border-radius: 0;
  letter-spacing: 1px;
  outline: none;
  padding: 3px 0 10px 0;
  width: 100%;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.sidebar .widget.widget_search .search-field:focus {
  border-bottom-color: #c0af94;
}

.sidebar .widget.widget_search .search-submit {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  background-color: #131313;
  border: 0;
  border-radius: 0;
  color: #fff;
  outline: none;
  padding: 12px;
  transition: background-color .3s ease-out;
}

.sidebar .widget.widget_search .search-submit:hover {
  background-color: #c0af94;
}

.sidebar .widget.widget_rss .widget-title .rsswidget {
  margin-bottom: 0;
}

.sidebar .widget.widget_rss li {
  margin-bottom: 20px;
}

.sidebar .widget.widget_rss .rsswidget {
  display: inline-block;
  font-style: italic;
  margin-bottom: 15px;
  margin-right: 10px;
  line-height: 20px;
}

.sidebar .widget.widget_rss .rss-date,
.sidebar .widget.widget_rss cite {
  font-family: "Libre Baskerville", serif;
  color: #131313;
  display: inline-block;
  margin-bottom: 10px;
}

.sidebar .widget.widget_rss cite {
  margin-top: 10px;
}

.sidebar .widget.widget_rss .rssSummary {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", serif;
  color: #888;
  letter-spacing: 1.12px;
}

.sidebar .widget.widget_calendar table {
  border: 0;
}

.sidebar .widget.widget_calendar table thead th,
.sidebar .widget.widget_calendar table tbody td,
.sidebar .widget.widget_calendar table caption {
  border: 0;
  font: 700 12px "Montserrat", sans-serif;
  text-align: center;
  padding: 10px;
}

.sidebar .widget.widget_calendar table caption {
  color: #131313;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.sidebar .widget.widget_calendar table tfoot a {
  font: 700 12px "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #131313;
}

.sidebar .widget.widget_calendar table tfoot td {
  padding: 10px;
}

.sidebar .widget.widget_calendar table tfoot #prev {
  text-align: left;
}

.sidebar .widget.widget_calendar table tfoot #next {
  text-align: right;
}

.sidebar .widget.widget_calendar table thead {
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
}

.sidebar .widget.widget_calendar table thead th {
  color: #131313;
  padding: 8px;
}

.sidebar .widget.widget_calendar table tbody td {
  color: #888;
}

.sidebar .widget.widget_calendar table tbody #today {
  display: -webkit-inline-box;
  background-color: #c0af94;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  line-height: 9px;
}

.sidebar .widget.widget_calendar table tbody #today a {
  color: #fff;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 08 - CONTACT PAGE */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.contact-page .left-contact {
  background-color: #f2f2f2;
  padding: 65px 68px 60px;
}

.contact-page .left-contact .title-post {
  margin-bottom: 0;
}

.contact-page .left-contact strong {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  color: #131313;
  text-transform: uppercase;
  margin-top: 20px;
}

.contact-page .left-contact p {
  margin-bottom: 0;
}

.contact-page .left-contact a {
  font-family: "Libre Baskerville", serif;
  color: #888;
  transition: color .3s ease-out;
}

.contact-page .left-contact a:hover {
  color: #131313;
}

.wpcf7-form h3 {
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 26px;
}

.wpcf7-form p {
  margin-bottom: 25px;
}

.wpcf7 input,
.wpcf7 textarea {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  color: #888;
  border: 0;
  border-bottom: 2px solid #f7f7f7;
  border-radius: 0;
  letter-spacing: 1px;
  outline: none;
  padding: 5px 0 10px 0;
  width: 100%;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.wpcf7 input:focus,
.wpcf7 textarea:focus {
  border-bottom-color: #c0af94;
}

.wpcf7 input::-webkit-input-placeholder, .wpcf7 input::-moz-placeholder, .wpcf7 input:-moz-placeholder, .wpcf7 input:-ms-input-placeholder,
.wpcf7 textarea::-webkit-input-placeholder,
.wpcf7 textarea::-moz-placeholder,
.wpcf7 textarea:-moz-placeholder,
.wpcf7 textarea:-ms-input-placeholder {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  color: #888;
}

.wpcf7 textarea {
  height: 75px;
  padding-bottom: 18px;
  resize: none;
}

.wpcf7 input[type="submit"],
.btn-error {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #131313;
  background-color: transparent;
  border: none;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  box-sizing: border-box;
  letter-spacing: 1.1px;
  line-height: 20px;
  padding: 8px 53px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  transition: all .3s ease-out;
  width: inherit;
  margin-top: 7px;
}

.wpcf7 input[type="submit"]:visited,
.btn-error:visited {
  color: #131313;
}

.wpcf7 input[type="submit"]:focus,
.btn-error:focus {
  text-decoration: none;
}

.wpcf7 input[type="submit"]:hover,
.btn-error:hover {
  background-color: #131313;
  color: #fff;
  border-color: #131313;
}

.wpcf7-not-valid {
  border-bottom: 2px solid red !important;
}

div.wpcf7-response-output {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  line-height: 20px;
  border: none;
  margin: 0;
  padding: 0;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 09 - 404 PAGE */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.content-error {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  text-align: center;
}

.content-error .overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.content-error .title-error {
  font-family: "Oswald", sans-serif;
  font-size: 70px;
  color: #fff;
  letter-spacing: 5.6px;
  text-transform: uppercase;
}

.content-error .subtitle-error {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: #fff;
  line-height: 28px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  margin: 10px 0;
}

.content-error .btn-error {
  width: initial;
  padding: 8px 23px;
  margin-top: 15px;
  color: #fff;
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.content-error .btn-error:hover {
  background-color: #fff;
  color: #131313;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 10 - SEARCH PAGE */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.search .search-field {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  color: #888;
  border: 0;
  border-bottom: 2px solid #f7f7f7;
  border-radius: 0;
  letter-spacing: 1px;
  outline: none;
  padding: 3px 0 10px 0;
  width: 100%;
  transition: all .3s ease-out;
}

.search .search-field:focus {
  border-bottom-color: #c0af94;
}

.search .search-submit {
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  background-color: #131313;
  border: 0;
  border-radius: 0;
  color: #fff;
  outline: none;
  padding: 12px;
  transition: background-color .3s ease-out;
}

.search .search-submit:hover {
  background-color: #c0af94;
}

@media (max-width: 1450px) {
  .show-sidebar {
    margin: 0;
  }
  #secondary {
    right: -15px;
  }
}

@media (max-width: 1024px) {
  .site-header .main-menu .menu-item-has-children {
    position: relative;
  }
  .site-header .main-menu .menu-item-has-children .fa-angle-right {
    color: #fff;
    transform: rotate(90deg);
    margin-left: 5px;
    position: absolute;
    right: 0;
    top: 6px;
  }
  .site-header .main-menu .menu-item-has-children .sub-menu {
    opacity: 0;
    visibility: hidden;
  }
  .site-header .main-menu .menu-item-has-children .sub-menu.active {
    opacity: 1;
    visibility: visible;
    position: static;
    min-width: 0;
    border-left: 0;
    padding: 0;
    margin-top: 5px;
    text-align: right;
  }
  .site-header .main-menu .menu-item-has-children .sub-menu.active:before {
    display: none;
  }
  .site-header .main-menu .menu-item-has-children .sub-menu.active li {
    padding-right: 0;
  }
  .site-header .main-menu .menu-item-has-children .sub-menu.active .menu-item .fa-angle-right {
    right: -20px;
  }
  .widget_nav_menu .menu-item .fa {
    display: none;
  }
}

@media (max-width: 991px) {
  .toggle-button {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding: 0 15px;
  }
  .content-site.blog-page {
    padding: 50px 0;
  }
  .site-header .content-header {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .contact-page {
    padding: 0 15px;
  }
  .contact-page .right-contact {
    margin-top: 60px;
  }
  .content-site {
    padding: 50px 15px;
  }
  .page-template-albums .content-site {
    padding: 50px 0;
  }
  .post.sticky:before {
    top: -20px;
    left: 15px;
  }
  .post.col-md-12 {
    margin-bottom: 25px;
  }
  .post.col-md-12 .title-albums {
    margin-top: 20px;
  }
  .post.col-md-12 .title-albums h3 {
    font-size: 24px;
  }
  #secondary {
    position: static;
    right: inherit;
  }
  .sidebar .widget .img {
    height: 300px;
  }
  .post-image {
    margin-bottom: 15px;
  }
  .page-template-about .content-site {
    padding: 50px 15px;
  }
  .albums .title-albums {
    text-align: center;
  }
  .albums .title-albums h5 {
    font-size: 24px;
  }
  .c-button--menu {
    position: absolute;
    top: 25px;
    margin-left: 15px;
    transform: none;
  }
  .page-template-contact .c-button--menu,
  .page-template-about .c-button--menu,
  .single-post .c-button--menu {
    margin-left: 0;
  }
  .single-albums .single-albums-image {
    margin-left: 0;
    margin-right: 0;
  }
  .single-albums .content-site .container {
    width: initial;
    padding: 0;
  }
  .single-albums .full-size-button {
    right: 15px;
  }
  .single-albums .c-button--menu {
    margin-left: 0;
  }
  .site-header {
    width: 270px;
  }
  .site.active {
    left: 270px;
  }
  .comment-respond .comment-form input,
  .comment-respond .comment-form textarea {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .light-comments-list .comment-body {
    padding-left: 16%;
  }
  .contact-page .left-contact {
    padding: 30px;
  }
  .single-albums-image .img-list-gallery {
    margin-bottom: 30px;
  }
  .full-size-button {
    position: absolute;
    top: 20px;
  }
  .light-comments-list .children {
    padding-left: 30px;
  }
  .site-header .content-header {
    top: 45px;
    transform: translateX(-50%);
  }
}

@media (max-width: 600px) {
  .post-image,
  .page-image,
  .albums.col-md-12 .albums-image,
  .post.col-md-12 .albums-image {
    height: 300px;
  }
  .light-comments-list .comment-img {
    float: none;
    margin-bottom: 20px;
  }
  .light-comments-list .comment-body {
    padding-left: 0;
  }
  .light-comments-list .comment-body .comment-title {
    display: block;
  }
  .light-comments-list .children {
    padding-left: 0;
  }
}

@media (max-width: 450px) {
  .post-image,
  .page-image {
    height: 200px;
  }
}

@media (min-width: 992px) {
  .no-padd-md {
    padding-left: 0;
    padding-right: 0;
  }
  .no-padd-l {
    padding-left: 0;
  }
  .no-padd-r {
    padding-right: 0;
  }
  .albums.col-md-6:nth-child(2n+1) {
    padding-right: 40px;
  }
  .albums.col-md-6:nth-child(2n) {
    padding-left: 40px;
  }
  .site.active {
    left: 335px;
  }
}

@media (min-width: 1025px) {
  .site-header .main-menu .menu-item .sub-menu {
    opacity: 0;
    visibility: hidden;
    background-color: #131313;
    border-left: 1px solid #c0af94;
    padding: 7px 20px 14px;
    text-align: left;
    position: absolute;
    top: 5px;
    left: 102%;
    min-width: 200px;
    margin: 0;
    z-index: 1000;
  }
  .site-header .main-menu .menu-item .sub-menu:before {
    content: '';
    border: 6px solid transparent;
    border-right: 6px solid #c0af94;
    position: absolute;
    top: 15px;
    left: -12px;
  }
  .site-header .main-menu .menu-item .sub-menu a {
    color: #fff;
    text-transform: capitalize;
    transition: color .3s ease-out;
  }
  .site-header .main-menu .menu-item .sub-menu a:hover {
    color: #c0af94;
  }
  .site-header .main-menu .menu-item .sub-menu .sub-menu {
    display: none;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 5px;
    min-width: 100px;
  }
  .site-header .main-menu .menu-item .sub-menu .sub-menu li {
    line-height: 0;
  }
  .site-header .main-menu .menu-item .sub-menu .sub-menu li a {
    line-height: 17px;
  }
  .site-header .main-menu .menu-item .sub-menu .sub-menu a:hover {
    color: #808080;
  }
  .site-header .main-menu .menu-item .sub-menu .menu-item:hover > .sub-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    position: static;
    border-left-color: #2b2b2b;
  }
  .site-header .main-menu .menu-item .sub-menu .menu-item:hover > .sub-menu:before {
    display: none;
  }
  .site-header .main-menu .menu-item:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .no-padd-lg {
    padding-left: 0;
    padding-right: 0;
  }
  .page-template-about .content-site {
    padding: 30px 0 0;
  }
  .right-15 {
    right: -15px;
  }
  .left-15 {
    left: -15px;
  }
}

@media (min-width: 1240px) {
  .no-padd-r-lg {
    padding-right: 0;
  }
}

@media (min-width: 1450px) {
  #secondary {
    position: absolute;
    top: 0;
    right: -100px;
  }
  .contact-page {
    padding: 14% 0 14% 18px;
  }
  .contact-page .right-contact {
    padding-left: 8px;
  }
  .page-template-about .content-site {
    padding: 115px 0;
  }
}

@media (min-width: 1600px) {
  .site.active {
    left: 150px;
  }
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* This is main CSS file that contains custom style rules used in this template */
/*-------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------*/
/* TABLE OF CONTENTS: */
/*--------------------------------------------------------*/
/* 00 - VARIABLES */
/* 01 - CSS RESET BY ERIC MEYER */
/* 02 - HEADER */
/* 03 - FOOTER */
/*-------------------------------------------------------------------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700|Montserrat:100,200,400,500,600,700,800,900|Oswald:200,300,400,500,600,700');
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 00 - VARIABLES */
/*-------------------------------------------------------------------------------------------------------------------------------*/
$color1: #131313;
$color2: #888;
$color3: #c0af94;

$font1: 'Oswald', sans-serif;
$font2: 'Libre Baskerville', serif;
$font3: 'Montserrat', sans-serif;

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 01 - CSS RESET BY ERIC MEYER */
/*-------------------------------------------------------------------------------------------------------------------------------*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
  border-left: 3px solid #333;
  padding-left: 20px;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #333;
    text-align: center;
    margin-bottom: 15px;
    width: 100%;

    thead,
    tbody {
        th,
        td {
            font-family: $font2;
            font-size: 14px;
            border: 1px solid #333;
            text-align: center;
            padding: 8px;
        }
    }
}

ul,
ol {
  margin-bottom: 20px;
  li {
    ul,
    ol {
      padding-left: 20px;
    }
  }
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}

dl {
  margin-bottom: 20px;
  dd {
    padding-left: 20px;
  }
}

li,
dl,
dd {
    font-family: $font2;
    font-size: 14px;
    line-height: 24px;
}

h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 15px;
    font-weight: 400;
    font-family: $font1;
    color: $color1;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p,
.wp-caption-text,
.pingback,
address {
    font-size: 14px;
    line-height: 28px;
    font-family: $font2;
    color: $color2;
    letter-spacing: 1.12px;
    margin-bottom: 30px;
}

.pingback {
    margin-bottom: 15px;
}

blockquote {
    margin: 25px 0 30px;
    overflow: hidden;
    text-align: center;
    padding: 18px 42px 20px 28px;
    border-left: 4px solid #c0af94;
    background-color: #ebebeb;
    p {
        font-size: 16px;
        line-height: 31px;
        font-family: $font2;
        color: #131313;
        text-align: left;
        font-style: normal;
        margin: 0;
        letter-spacing: 1.28px;
    }
}

pre {
    font-family: $font2;
    font-size: 16px;
    color: $color1;
    line-height: 31px;
    padding: 20px;
    margin-bottom: 20px;
}

strong {
  font-weight: 700;
}

acronym {
    border-bottom: 1px dashed #777;
}

big {
    font-size: 2em;
}

i, cite, em, var, address, dfn {
    font-style: italic;
}

s, strike, del {
    text-decoration: line-through;
}

a,
a:visited {
    color: $color3;
}

a:hover {
    color: $color1;
    text-decoration: none;
}

a:focus,
a:active {
    text-decoration: none;
}

.rel-position {
    position: relative;
}

.no-padd {
  padding-left: 0;
  padding-right: 0;
}

.marg-10t {
    margin-top: 10px;
}

.marg-35t {
    margin-top: 35px;
}

.marg-40t {
    margin-top: 40px;
}

.marg-65t {
    margin-top: 65px;
}
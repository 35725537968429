body {
    overflow-x: hidden;
}

.site {
    position: relative;
    left: 0;
    transition: left .5s ease-out;
}

.content-site {
    padding: 80px 15px;
    position: relative;
    /** Full size content **/
    &.container {
        transition: width .3s ease-out;
    }
    .container {
        transition: width .3s ease-out;
        .col-md-8 {
            transition: width .3s ease-out;
        }
    }
}

.page-template-about {
    .content-site {
        padding: 15px;
    }
}

.vertical-align {
    position: absolute;
    width: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.overlay {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

#loading {
    background-color: #fff;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    margin-top: 0;
    top: 0;
    transition: background-color .3s ease-out;
}


/*-------------------------------------------------------------------------------------------------------------------------------*/


/* 02 - HEADER */


/*-------------------------------------------------------------------------------------------------------------------------------*/

.no-menu {
    margin-bottom: 15px;
    font-weight: 400;
    font-family: $font1;
    color: #fff;
    line-height: 24px;
    a:hover {
        color: $color2;
    }
}

.site-header {
    width: 335px;
    float: left;
    text-align: right;
    position: fixed;
    left: -335px;
    z-index: 100;
    background-color: $color1;
    transition: left .5s ease-out;
    height: 100%;
    padding-right: 20px;
    &.active {
        left: 0;
    }
    .content-header {
        position: absolute;
        top: 50%;
        left: 110px;
        max-width: 200px;
        transform: translateY(-50%);
    }
    /** Logo site **/
    .site-title {
        a {
            font-family: $font1;
            font-size: 18px;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
    .site-description {
        font: normal 12px $font2;
        color: #8f8f8f;
    }
    /** Navigation menu **/
    .main-menu {
        margin-bottom: 28px;
        margin-right: -20px;
        .menu-item {
            list-style: none;
            position: relative;
            padding-right: 20px;
            line-height: 28px;
            a {
                font: normal 12px $font3;
                color: #fff;
                text-transform: uppercase;
                line-height: 28px;
                transition: color .3s ease-out;
                &:hover {
                    color: $color3;
                    text-decoration: none;
                }
            }
            &.current-menu-item {
                a {
                    color: $color3;
                }
            }
            .sub-menu {
                opacity: 0;
                visibility: hidden;
                background-color: $color1;
                border-left: 1px solid $color3;
                padding: 7px 20px 14px;
                text-align: left;
                position: absolute;
                top: 5px;
                left: 102%;
                min-width: 200px;
                margin: 0;
                z-index: 1000;
                &:before {
                    content: '';
                    border: 6px solid transparent;
                    border-right: 6px solid $color3;
                    position: absolute;
                    top: 15px;
                    left: -12px;
                }
                a {
                    color: #fff;
                    text-transform: capitalize;
                    transition: color .3s ease-out;
                    &:hover {
                        color: $color3;
                    }
                }
            }
        }
    }
    /** Social icons **/
    .social-icons {
        padding-top: 3px;
        li {
            list-style: none;
            display: inline-block;
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
            .fa {
                color: #8c8c8c;
                font-size: 12px;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
    .copyright-footer {
        font: normal 12px $font2;
        color: #8f8f8f;
    }
}


/** Menu button **/

.c-button {
    height: 100%;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: visible;
    background-color: transparent;
    text-transform: none;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    text-align: center;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    z-index: 100;
}

.c-button:hover,
.c-button:focus {
    text-decoration: none;
    outline: none;
}

.c-button:not(:disabled) {
    cursor: pointer;
}

.c-button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.c-button--menu {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 28px;
    height: 14px;
    font-size: 0;
    line-height: 0;
    transition: left .5s ease-out;
    z-index: 50;
}

.c-button--menu:before,
.c-button--menu:after,
.c-button--menu .c-button__icon,
.c-button--menu .c-button__icon:before {
    position: absolute;
    width: 28px;
    height: 2px;
    background: #e4e4e4;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

.c-button--menu:before,
.c-button--menu:after {
    content: "";
    left: 0;
    right: 0;
}

.c-button--menu:before {
    top: 10px;
}

.c-button--menu:after {
    bottom: 14px;
}

.c-button--menu .c-button__icon {
    top: 50%;
    left: 50%;
    margin-left: -14px;
    margin-top: -3px;
}

.c-button--menu .c-button__icon:before {
    content: "";
    top: 0;
    left: 0;
}

.c-button--menu.is-active:before {
    left: 100%;
    width: 0;
}

.c-button--menu.is-active:after {
    right: 100%;
    width: 0;
}

.c-button--menu.is-active .c-button__icon {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.c-button--menu.is-active .c-button__icon:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}


/*-------------------------------------------------------------------------------------------------------------------------------*/


/* 03 - PAGE ABOUT */


/*-------------------------------------------------------------------------------------------------------------------------------*/

.post-image,
.page-image,
.recent-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.post-image {
    height: 600px;
    margin-top: 4px;
}

.page-image {
    height: 500px;
}

.title-post,
.comment-reply-title,
.comment-title {
    font-family: $font1;
    line-height: 40px;
    color: #131313;
    letter-spacing: 3.1px;
    margin: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
    word-wrap: break-word;
}

.comment-reply-title {
    font-size: 28px;
    letter-spacing: 2.8px;
    margin-bottom: 24px;
}

.subtitle-post,
.comment-date,
.comment-reply-link,
#cancel-comment-reply-link {
    font-family: $font3;
    color: $color3;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.4px;
    margin-bottom: 29px;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    transition: color .3s ease-out;
}

#cancel-comment-reply-link:hover {
    color: $color1;
}

.link-post,
.load-btn,
button.comment-btn {
    display: inline-block;
    font-family: $font3;
    font-size: 11px;
    font-weight: bold;
    color: $color1;
    background-color: transparent;
    border: none;
    border-top: 2px solid rgba(19, 19, 19, 0.5);
    border-bottom: 2px solid rgba(19, 19, 19, 0.5);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-sizing: border-box;
    letter-spacing: 1.1px;
    line-height: 20px;
    padding: 8px 28px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    transition: all .3s ease-out;
    &:visited {
        color: $color1;
    }
    &:focus {
        text-decoration: none;
    }
    &:hover {
        background-color: $color1;
        color: #fff;
        border-color: $color1;
    }
}

.link-post {
    margin-top: 10px;
}


/*-------------------------------------------------------------------------------------------------------------------------------*/


/* 04 - PAGE ALBUMS */


/*-------------------------------------------------------------------------------------------------------------------------------*/

.albums,
.post {
    position: relative;
    &.col-md-4 {
        .albums-image {
            height: 250px;
        }
    }
    &.col-md-6 {
        .albums-image {
            height: 305px;
        }
    }
    &.col-md-12 {
        .albums-image {
            height: 470px;
        }
    }
    .img-gallery {
        width: 33.3333%;
        height: 33.3333%;
        float: left;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .albums-image {
        position: relative;
        a {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            display: block;
            width: 100%;
            height: 100%;
        }
        .overlay {
            background-color: rgba(0, 0, 0, 0.3);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease-out;
        }
        &:hover .overlay {
            opacity: 1;
            visibility: visible;
        }
    }
    .content-post {
        text-align: right;
    }
    .title-albums {
        display: block;
        margin-top: 14px;
        margin-bottom: 0;
        text-align: right;
        h3,
        h5 {
            text-transform: uppercase;
            letter-spacing: 3.1px;
            transition: color .2s ease-out;
            &:hover {
                color: $color3 !important;
            }
        }
    }
    &.col-md-12 {
        margin-bottom: 80px;
        .title-albums {
            margin-top: 40px;
            text-align: center;
            h3,
            h5 {
                font-size: 36px;
            }
        }
    }
}

.albums {
    margin-bottom: 25px;
    .title-albums {
        margin-top: 20px;
        h5 {
            letter-spacing: 1.8px;
        }
    }
}

.post {
    margin-bottom: 34px;
    &.sticky {
        position: relative;
        &:before {
            position: absolute;
            top: 15px;
            left: 30px;
            font-family: FontAwesome;
            content: '\f08d';
            font-size: 20px;
            transform: rotate(325deg);
            z-index: 5;
        }
    }
    &.col-md-12 {
        margin-bottom: 48px;
        .title-albums {
            margin-top: 35px;
        }
        .content-post {
            text-align: center;
        }
    }
}


/** Pagination **/

.pagination,
.navigation {
    display: inline-block;
    width: 100%;
    .next-post,
    .previus-post {
        position: relative;
        a,
        span {
            font-size: 14px;
            font-family: $font3;
            color: #585858;
            line-height: 1.7;
            letter-spacing: .7px;
            text-decoration: none;
            position: relative;
            transition: color .3s ease-out;
            &:hover {
                color: $color3;
            }
            &:focus {
                text-decoration: none;
            }
        }
        i {
            font-size: 18px;
            position: absolute;
            top: 49%;
            -webkit-transform: translateY(-49%);
            transform: translateY(-49%);
            color: $color3;
        }
    }
    .previus-post {
        float: left;
        padding-left: 20px;
        i {
            left: 0;
            transition: left .3s ease;
        }
        &:hover {
            i {
                left: 5px;
                opacity: .7;
            }
        }
    }
    .next-post {
        float: right;
        padding-right: 20px;
        i {
            right: 0;
            transition: right .3s ease;
        }
        &:hover {
            i {
                right: 5px;
                opacity: .7;
            }
        }
    }
}


/*-------------------------------------------------------------------------------------------------------------------------------*/


/* 05 - SINGLE ALBUMS */


/*-------------------------------------------------------------------------------------------------------------------------------*/

.full-size-button {
    position: fixed;
    top: 30px;
    right: 30px;
    cursor: pointer;
    // opacity: 0;
    visibility: visible;
    .fa {
        color: #d3d3d3;
        font-size: 20px;
    }
}

.single-albums-image {
    margin-left: 15px;
    margin-right: 15px;
    .img-list-gallery {
        margin-bottom: 80px;
        cursor: pointer;
        position: relative;
        .full-size-button {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 15px;
            right: 30px;
            transition: opacity .3s ease-out;
        }
        &:hover {
            .full-size-button {
                opacity: 1;
                visibility: visible;
            }
        }
        &.col-md-6,
        &.col-md-4 {
            margin-bottom: 30px;
            .img-wrapp {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        &.col-md-6 {
            .img-wrapp {
                height: 250px;
                transition: height .3s ease-out;
            }
        }
        &.col-md-4 {
            .img-wrapp {
                height: 200px;
                transition: height .3s ease-out;
            }
        }
        img {
            width: 100%;
        }
    }
}


/** Popup albums type **/

#content-site.full-size-list {
    >.full-size-button {
        opacity: 1;
        visibility: visible;
    }
}

#content-site.full-popup,
#content-site.full-popup .container,
#content-site.full-popup .col-md-8,
#content-site.full-popup .col-md-6,
#content-site.full-popup .col-md-4 {
    width: 100%;
}

#content-site.full-popup {
    padding: 0;
}

#content-site.full-popup .pagination,
#content-site.full-popup .navigation {
    padding: 0 30px;
}

#content-site.full-popup .c-button--menu,
#content-site.full-popup .full-size-button {
    opacity: 0;
    visibility: hidden;
}

#content-site.full-popup .container {
    padding: 30px;
}

#content-site.full-popup .img-list-gallery {
    margin-bottom: 30px;
}

#content-site.full-popup .img-wrapp {
    background-position: -9999px;
    height: auto;
}

#content-site.full-size.full-popup .img-wrapp {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#content-site.full-size-list.full-popup .img-wrapp img {
    display: block !important;
}

#content-site.full-popup .col-md-8 {
    margin: 0;
    padding: 0;
}


/** Full size popup **/

.full-img-popup {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 30px;
    width: 100%;
    height: 100%;
    z-index: 100;
}


/** Default popup **/

.mfp-title {
    font-family: $font3;
    font-size: 12px;
    color: #fff;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 5px;
}


/*-------------------------------------------------------------------------------------------------------------------------------*/


/* 06 - SINGLE BLOG */


/*-------------------------------------------------------------------------------------------------------------------------------*/

.blog-default {
    .post {
        .overlay {
            display: none;
        }
        .albums-image {
            height: inherit !important;
            img {
                display: block !important;
            }
            a {
                background: none !important;
            }
        }
    }
}

.list-info {
    li {
        font-family: $font3;
        color: $color3;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        list-style: none;
        &:after {
            content: ' | ';
            color: $color1;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
        a {
            display: inline-block;
            color: $color3;
            transition: color .3s ease-out;
            &:hover {
                color: $color1;
            }
        }
    }
}

// Recent posts
.recent-posts {
    .title-post {
        margin-bottom: 35px;
    }
    .recent-image {
        height: 240px;
    }
    .post {
        .overlay {
            background-color: rgba(0, 0, 0, 0.3);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: all .3s ease-out;
        }
        &:hover {
            .overlay,
            .recent-content {
                opacity: 0;
                visibility: hidden;
            }
            .no-thumbnail {
                .recent-content {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .recent-content {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        transition: all .3s ease-out;
        .recent-title {
            font-family: $font1;
            font-size: 18px !important;
            text-transform: uppercase;
            color: #fff !important;
            letter-spacing: 2px;
            margin-bottom: 20px;
        }
        .recent-date {
            font-family: $font3;
            font-size: 11px;
            text-transform: uppercase;
            color: #fff;
            letter-spacing: 1.6px;
        }
    }
    .no-thumbnail {
        display: block;
        height: 240px;
        .recent-content {
            .recent-title,
            .recent-date {
                color: $color1 !important;
            }
        }
    }
}

// Comment post
.comment-respond {
    margin-top: 15px;
    margin-bottom: 30px;
    .comment-form {
        input,
        textarea {
            font-family: $font2;
            font-size: 14px;
            color: $color2;
            border: 0;
            border-bottom: 2px solid #f7f7f7;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            letter-spacing: 1px;
            outline: none;
            padding: 3px 0 15px 0;
            width: 100%;
            transition: all .3s ease-out;
            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-moz-placeholder,
            &:-ms-input-placeholder {
                font-family: $font2;
                font-size: 14px;
                color: $color2;
            }
            &:focus {
                border-bottom-color: $color3;
            }
        }
        textarea {
            padding-bottom: 18px;
            margin-top: 35px;
            resize: none;
        }
        .comment-btn {
            margin-top: 38px;
            padding: 8px 53px;
        }
    }
}

// Comment list
.light-comments-list {
    margin-top: 30px;
    ol,
    ul {
        list-style: none;
    }
    .comment-title {
        font-size: 19px;
        display: inline-block;
        margin-right: 20px;
        letter-spacing: 1.3px;
        margin-bottom: 13px;
        line-height: inherit;
    }
    .comment-img {
        margin-right: 20px;
        float: left;
    }
    .comment-body {
        padding-left: 12%;
        position: relative;
        h2 {
            margin-top: 20px;
        }
    }
    .comment-reply-link {
        position: absolute;
        top: 0;
        right: 0;
        &:hover {
            color: $color1;
        }
    }
    .children {
        padding-left: 70px;
    }
}


/*-------------------------------------------------------------------------------------------------------------------------------*/


/* 07 - WIDGETS */


/*-------------------------------------------------------------------------------------------------------------------------------*/

#secondary {
    margin-top: 4px;
}

.sidebar {
    .widget {
        margin-bottom: 75px;
        .widget-title {
            font: 700 12px $font3;
            color: $color1;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            margin-bottom: 26px;
        }
        ul {
            list-style: none;
            padding-left: 0;
        }
        li {
            margin-bottom: 12px;
        }
        strong {
            color: $color1;
        }
        a {
            font-family: $font2;
            color: $color2;
            transition: color .3s ease-out;
            &:hover {
                color: $color1;
            }
        }
        select {
            font-family: $font2;
            color: $color2;
            padding: 5px;
            width: 100%;
            option {
                font-family: $font2;
                color: $color2;
            }
        }
        .children,
        .sub-menu {
            padding-left: 0;
            margin-top: 12px;
        }
        .img {
            display: block;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 190px;
        }
        // Lasest posts
        &.widget_latest_posts {
            .img {
                position: relative;
                .overlay {
                    opacity: 0;
                    visibility: hidden;
                    transition: all .3s ease-out;
                }
                &:hover .overlay {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .link {
                display: block;
                font-family: $font3;
                font-size: 12px;
                color: $color2;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin-top: 15px;
                margin-bottom: 27px;
                &:hover {
                    color: $color3;
                }
            }
        }
        // categories
        &.widget_categories {
            .cat-item {
                color: $color3;
                a {
                    font-size: 14px;
                    text-transform: capitalize;
                    letter-spacing: 1.3px;
                }
            }
        }
        // Archive, Pages, Navigation menu
        &.widget_archive,
        &.widget_pages,
        &.widget_nav_menu {
            li {
                color: $color3;
            }
        }
        // Tags
        &.widget_tag_cloud {
            .tagcloud {
                a {
                    display: inline-block;
                    background-color: #f2f2f2;
                    font-size: 12px !important;
                    padding: 7px 10px 8px;
                    letter-spacing: 1px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    transition: all .3s ease-out;
                    &:hover {
                        background-color: $color3;
                    }
                }
            }
        }
        // Recent posts
        &.widget_recent_comments {
            .recentcomments {
                font-family: $font2;
                line-height: 20px;
            }
        }
        // Search widget
        &.widget_search {
            .search-field {
                font-family: "Libre Baskerville", serif;
                font-size: 14px;
                color: $color2;
                border: 0;
                border-bottom: 2px solid #f7f7f7;
                border-radius: 0;
                letter-spacing: 1px;
                outline: none;
                padding: 3px 0 10px 0;
                width: 100%;
                -webkit-transition: all .3s ease-out;
                transition: all .3s ease-out;
                &:focus {
                    border-bottom-color: $color3;
                }
            }
            .search-submit {
                font-family: "Libre Baskerville", serif;
                font-size: 14px;
                background-color: $color1;
                border: 0;
                border-radius: 0;
                color: #fff;
                outline: none;
                padding: 12px;
                transition: background-color .3s ease-out;
                &:hover {
                    background-color: $color3;
                }
            }
        }
        // RSS
        &.widget_rss {
            .widget-title {
                .rsswidget {
                    margin-bottom: 0;
                }
            }
            li {
                margin-bottom: 20px;
            }
            .rsswidget {
                display: inline-block;
                font-style: italic;
                margin-bottom: 15px;
                margin-right: 10px;
                line-height: 20px;
            }
            .rss-date,
            cite {
                font-family: $font2;
                color: $color1;
                display: inline-block;
                margin-bottom: 10px;
            }
            cite {
                margin-top: 10px;
            }
            .rssSummary {
                font-size: 14px;
                line-height: 28px;
                font-family: $font2;
                color: #888;
                letter-spacing: 1.12px;
            }
        }
        // Calendar
        &.widget_calendar {
            table {
                border: 0;
                thead th,
                tbody td,
                caption {
                    border: 0;
                    font: 700 12px $font3;
                    text-align: center;
                    padding: 10px;
                }
                caption {
                    color: $color1;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }
                tfoot {
                    a {
                        font: 700 12px $font3;
                        text-transform: uppercase;
                        color: $color1;
                    }
                    td {
                        padding: 10px;
                    }
                    #prev {
                        text-align: left;
                    }
                    #next {
                        text-align: right;
                    }
                }
                thead {
                    border-top: 2px solid #f2f2f2;
                    border-bottom: 2px solid #f2f2f2;
                    th {
                        color: $color1;
                        padding: 8px;
                    }
                }
                tbody {
                    td {
                        color: $color2;
                    }
                    #today {
                        display: -webkit-inline-box;
                        background-color: $color3;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        border-radius: 50%;
                        color: #fff;
                        line-height: 9px;
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}


/*-------------------------------------------------------------------------------------------------------------------------------*/


/* 08 - CONTACT PAGE */


/*-------------------------------------------------------------------------------------------------------------------------------*/

.contact-page {
    .left-contact {
        background-color: #f2f2f2;
        padding: 65px 68px 60px;
        .title-post {
            margin-bottom: 0;
        }
        strong {
            display: block;
            font-family: $font3;
            font-size: 11px;
            color: $color1;
            text-transform: uppercase;
            margin-top: 20px;
        }
        p {
            margin-bottom: 0;
        }
        a {
            font-family: $font2;
            color: $color2;
            transition: color .3s ease-out;
            &:hover {
                color: $color1;
            }
        }
    }
}

.wpcf7-form {
    h3 {
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 26px;
    }
    p {
        margin-bottom: 25px;
    }
}

.wpcf7 input,
.wpcf7 textarea {
    font-family: $font2;
    font-size: 14px;
    color: $color2;
    border: 0;
    border-bottom: 2px solid #f7f7f7;
    border-radius: 0;
    letter-spacing: 1px;
    outline: none;
    padding: 5px 0 10px 0;
    width: 100%;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
    &:focus {
        border-bottom-color: $color3;
    }
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
        font-family: $font2;
        font-size: 14px;
        color: $color2;
    }
}

.wpcf7 textarea {
    height: 75px;
    padding-bottom: 18px;
    resize: none;
}

.wpcf7 input[type="submit"],
.btn-error {
    display: inline-block;
    font-family: $font3;
    font-size: 11px;
    font-weight: bold;
    color: $color1;
    background-color: transparent;
    border: none;
    border-top: 2px solid rgba(19, 19, 19, 0.5);
    border-bottom: 2px solid rgba(19, 19, 19, 0.5);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-sizing: border-box;
    letter-spacing: 1.1px;
    line-height: 20px;
    padding: 8px 53px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    transition: all .3s ease-out;
    width: inherit;
    margin-top: 7px;
    &:visited {
        color: $color1;
    }
    &:focus {
        text-decoration: none;
    }
    &:hover {
        background-color: $color1;
        color: #fff;
        border-color: $color1;
    }
}

.wpcf7-not-valid {
    border-bottom: 2px solid red!important;
}

div.wpcf7-response-output {
    font-family: $font2;
    font-size: 14px;
    line-height: 20px;
    border: none;
    margin: 0;
    padding: 0;
}


/*-------------------------------------------------------------------------------------------------------------------------------*/


/* 09 - 404 PAGE */


/*-------------------------------------------------------------------------------------------------------------------------------*/

.content-error {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    text-align: center;
    .overlay {
        background-color: rgba(0, 0, 0, 0.7);
    }
    .title-error {
        font-family: $font1;
        font-size: 70px;
        color: #fff;
        letter-spacing: 5.6px;
        text-transform: uppercase;
    }
    .subtitle-error {
        font-family: $font2;
        font-size: 14px;
        font-weight: 400;
        font-style: italic;
        color: #fff;
        line-height: 28px;
        letter-spacing: 1.12px;
        text-transform: uppercase;
        margin: 10px 0;
    }
    .btn-error {
        width: initial;
        padding: 8px 23px;
        margin-top: 15px;
        color: #fff;
        border-top-color: #fff;
        border-bottom-color: #fff;
        &:hover {
            background-color: #fff;
            color: $color1;
        }
    }
}


/*-------------------------------------------------------------------------------------------------------------------------------*/


/* 10 - SEARCH PAGE */


/*-------------------------------------------------------------------------------------------------------------------------------*/

.search {
    .search-field {
        font-family: $font2;
        font-size: 14px;
        color: $color2;
        border: 0;
        border-bottom: 2px solid #f7f7f7;
        border-radius: 0;
        letter-spacing: 1px;
        outline: none;
        padding: 3px 0 10px 0;
        width: 100%;
        transition: all .3s ease-out;
        &:focus {
            border-bottom-color: $color3;
        }
    }
    .search-submit {
        font-family: $font2;
        font-size: 14px;
        background-color: $color1;
        border: 0;
        border-radius: 0;
        color: #fff;
        outline: none;
        padding: 12px;
        transition: background-color .3s ease-out;
        &:hover {
            background-color: $color3;
        }
    }
}
@media ( max-width: 1450px ) {
	.show-sidebar {
		margin: 0;
	}

	#secondary {
		right: -15px;
	}
}

@media ( max-width: 1024px ) {
	.site-header {
		.main-menu {
			.menu-item-has-children {
				position: relative;
				.fa-angle-right {
					color: #fff;
					transform: rotate(90deg);
					margin-left: 5px;
					position: absolute;
					right: 0;
					top: 6px;
				}
				.sub-menu {
					opacity: 0;
					visibility: hidden;
					&.active {
						opacity: 1;
						visibility: visible;
						position: static;
						min-width: 0;
						border-left: 0;
						padding: 0;
						margin-top: 5px;
						text-align: right;
						&:before {
							display: none;
						}
						li {
							padding-right: 0;
						}
						.menu-item {
							.fa-angle-right {
								right: -20px;
							}
						}
						.sub-menu {

						}
					}
				}
			}
		}
	}

	.widget_nav_menu {
		.menu-item {
			.fa {
				display: none;
			}
		}
	}
}

@media ( max-width: 991px ) {
	.toggle-button {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 60px;
		line-height: 60px;
		padding: 0 15px;
	}

	.content-site {
		&.blog-page {
			padding: 50px 0;
		}
	}

	.site-header {
		.content-header {
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.contact-page {
		padding: 0 15px;
		.right-contact {
			margin-top: 60px;
		}
	}

	.content-site {
		padding: 50px 15px;
	}

	.page-template-albums {
		.content-site {
			padding: 50px 0;
		}
	}

	.post {
		&.sticky {
			&:before {
				top: -20px;
				left: 15px;
			}
		}

		&.col-md-12 {
			margin-bottom: 25px;
			.title-albums {
				margin-top: 20px;
				h3 {
					font-size: 24px;
				}
			}
		}
	}

	#secondary {
		position: static;
		right: inherit;
	}

	.sidebar {
		.widget {
			.img {
				height: 300px;
			}
		}
	}

	.post-image {
		margin-bottom: 15px;
	}

	.page-template-about {
		.content-site {
			padding: 50px 15px;
		}
	}

	.albums {
		.title-albums {
			text-align: center;
			h5 {
				font-size: 24px;
			}
		}
	}

	.c-button--menu {
		position: absolute;
		top: 25px;
		margin-left: 15px;
		transform: none;
	}

	.page-template-contact,
	.page-template-about,
	.single-post {
		.c-button--menu {
			margin-left: 0;
		}
	}

	.single-albums {
		.single-albums-image {
			margin-left: 0;
			margin-right: 0;
		}

		.content-site {
			.container {
				width: initial;
				padding: 0;
			}
		}

		.full-size-button {
			right: 15px;
		}

		.c-button--menu {
			margin-left: 0;
		}

	}

	.site-header {
		width: 270px;
	}

	.site {
		&.active {
			left: 270px;
		}
	}

	.comment-respond {
		.comment-form {
			input,
			textarea {
				margin-bottom: 15px;
			}
		}
	}
}

@media ( max-width: 768px ) {
	.light-comments-list {
		.comment-body {
			padding-left: 16%;
		}
	}

	.contact-page {
		.left-contact {
			padding: 30px;
		}
	}

	.single-albums-image {
		.img-list-gallery {
			margin-bottom: 30px;
		}
	}

	.full-size-button {
		position: absolute;
		top: 20px;
	}

	.light-comments-list {
		.children {
			padding-left: 30px;
		}
	}

	.site-header {
		.content-header {
			top: 45px;
			transform: translateX(-50%);
		}
	}
}
@media ( max-width: 600px ) {
	.post-image,
	.page-image,
	.albums.col-md-12 .albums-image,
	.post.col-md-12 .albums-image {
		height: 300px;
	}

	.light-comments-list {
		.comment-img {
			float: none;
			margin-bottom: 20px;
		}
		.comment-body {
			padding-left: 0;
			.comment-title {
				display: block;
			}
		}
	}

	.light-comments-list {
		.children {
			padding-left: 0;
		}
	}

}

@media ( max-width: 450px ) {
	.post-image,
	.page-image {
		height: 200px;
	}
}

@media ( min-width: 992px ) {
	.no-padd-md {
		padding-left: 0;
		padding-right: 0;
	}

	.no-padd-l {
		padding-left: 0;
	}

	.no-padd-r {
		padding-right: 0;
	}

	.albums {
		&.col-md-6 {
			&:nth-child(2n+1) {
				padding-right: 40px;
			}
			&:nth-child(2n) {
				padding-left: 40px;
			}
		}
	}

	.site {
		&.active {
			left: 335px;
		}
	}
}

@media ( min-width: 1025px ) {
	.site-header {
		.main-menu {
			.menu-item {
				.sub-menu {
					opacity: 0;
					visibility: hidden;
					background-color: $color1;
					border-left: 1px solid $color3;
					padding: 7px 20px 14px;
					text-align: left;
					position: absolute;
					top: 5px;
					left: 102%;
					min-width: 200px;
					margin: 0;
					z-index: 1000;
					&:before {
						content: '';
						border: 6px solid transparent;
						border-right: 6px solid $color3;
						position: absolute;
						top: 15px;
						left: -12px;
					}
					a {
						color: #fff;
						text-transform: capitalize;
						transition: color .3s ease-out;
						&:hover {
							color: $color3;
						}
					}
				}
				.sub-menu {
					.sub-menu {
						display: none;
						opacity: 0;
						visibility: hidden;
						padding: 0;
						padding-left: 10px;
						margin-top: 0px;
						margin-bottom: 5px;
						min-width: 100px;
						li {
							line-height: 0;
							a {
								line-height: 17px;
							}
						}
						a:hover {
							color: #808080;
						}
					}
					.menu-item:hover {
						> .sub-menu {
							display: block;
							opacity: 1;
							visibility: visible;
							position: static;
							border-left-color: #2b2b2b;
							&:before {
								display: none;
							}
						}
					}
				}
				&:hover > .sub-menu {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

@media ( min-width: 1200px ) {
	.no-padd-lg {
		padding-left: 0;
		padding-right: 0;
	}
	.page-template-about {
		.content-site {
			padding: 30px 0 0;
		}
	}

	.right-15 {
		right: -15px;
	}

	.left-15 {
		left: -15px;
	}
}

@media ( min-width: 1240px ) {
	.no-padd-r-lg {
		padding-right: 0;
	}
}

@media ( min-width: 1450px ) {
	#secondary {
		position: absolute;
		top: 0;
		right: -100px;
	}
	.contact-page {
		padding: 14% 0 14% 18px;
		.right-contact {
			padding-left: 8px;
		}
	}
	.page-template-about {
		.content-site {
			padding: 115px 0;
		}
	}
}

@media ( min-width: 1600px ) {
	.site {
		&.active {
			left: 150px;
		}
	}
}